const phoneCodeTime = 'phoneCodeTime';

// 季哥服务器登录态维护
export function getPhoneCodeTime() {
  return Number(localStorage.getItem(phoneCodeTime));
}

export function updatePhoneCodeTime() {
  localStorage.setItem(phoneCodeTime, String(new Date().getTime()));
}

export function removePhoneCodeTime() {
  localStorage.removeItem(phoneCodeTime);
}
