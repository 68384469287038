<template lang="pug">
  div.login-container
    el-card.login-card
      div.logo-container
        span.logo LOP
        span 直播间
      el-form.form-container(:model="loginForm" ref="loginForm")
        el-form-item(prop="phone")
          el-input(
          v-model="loginForm.phone"
          placeholder="请输入账号"
          ref="phone"
          )
        el-form-item(prop="code")
          el-input(
          v-model="loginForm.code"
          placeholder="请输入验证码"
          )
        el-form-item
          el-button.code-button(
          @click="handleGetCode"
          ) {{codeButtonText}}
        el-form-item
          el-button.login-button(
          type="primary"
          @click="handleLogin"
          ) 登录
</template>

<script>
import { removePhoneCodeTime, updatePhoneCodeTime, getPhoneCodeTime } from '../utils/storage';

export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        phone: '',
        code: '',
      },
    };
  },
  computed: {
    codeButtonText() {
      const countDown = this.$store.state.phoneCodeCountDown;
      return countDown > 0 ? `${countDown}s后重发` : '发送验证码';
    },
  },
  mounted() {
    // 上次验证码发送后未到60s，则继续倒计时
    const leftCount = Math.max(0, 60 - Math.round((new Date().getTime() - getPhoneCodeTime()) / 1000));
    if (leftCount > 0) {
      this.$store.dispatch('startPhoneCodeCountDown', leftCount);
    }
  },
  methods: {
    handleLogin() {
      const loginInfo = this.loginForm;
      const { phone, code } = this.loginForm;
      if (phone.length !== 11) {
        this.$message.error('手机号不能为空');
        return;
      }
      if (!code) {
        this.$message.error('验证码不能为空');
        return;
      }
      this.$store.dispatch('phoneLogin', loginInfo)
        .then(() => {
          this.$message.success('登录成功');
          // 登录成功后清空倒计时
          removePhoneCodeTime();
          // 页面跳转
          this.$router.push({ path: '/' });
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    handleGetCode() {
      const { phone } = this.loginForm;
      const countDown = this.$store.state.phoneCodeCountDown;
      if (countDown > 0) {
        this.$message.error(`请等待${countDown}秒后再做尝试`);
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('手机号不能为空');
        return;
      }
      this.$store.dispatch('getPhoneCode', phone)
        .then(() => {
          // 记录倒计时时间，刷新界面后仍能够继续倒计时
          updatePhoneCodeTime();
          this.$message.success('验证发送成功');
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
  .login-container
    width 100%
    height 100%
    position relative
    display flex
    justify-content center
    align-items center
    .login-card
      position relative
      width 400px
      height 400px
      .logo-container
        margin 36px 0 32px 0
        display flex
        align-items center
        justify-content center
        font-size 26px
        .logo
          color #0076ff
          font-size 32px
          font-family 'Times New Roman'
          font-weight 700
          margin-right: 10px
      .form-container
        width: 80%
        margin 0 auto
        .code-button
          margin-top 0px
          width 100%
        .login-button
          margin-top 0px
          width 100%
</style>

